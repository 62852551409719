import fr from "vee-validate/dist/locale/fr.json";

export default {
  HOME: "Accueil",
  BUY_NOW: "Acheter",
  PLAY: "Jouer",
  Note: "Note",
  WELCOME: "Bienvenue à Jackpot Aruba",
  PICK_NUMBERS: "Choisir vos numéros",
  PROCEED_CART: "Valider ticket",
  PROCEED_CHECKOUT: "Payer",
  CART_TOTAL: "Total",
  BACK: "Retour",
  RANDOMIZE_ALL: "Choisir au hasard",
  CLEAR_ALL: "Tout effacer",
  ADD_LINE: "Ajouter une ligne",
  LINE_NO: "Ticket #",
  MY_SHOPPING_CART: "Mon panier",

  CHECKOUT_MSG_2: "Règlement et conditions générales",
  DELETE: "Supprimer",
  PRICE: "Prix",
  PAY: "Payer",
  CHECKOUT: "Paiement",
  MY_WALLET: "Mon portefeuille",
  WALLET: "Wallet",
  LOTTO: "Loto",
  AVIATOR: "Aviator",
  AMOUNT: "Montant",
  TRANSACTIONS: "Transactions",
  ID: "Id",
  ID_TRANSACTION: "Id Transaction",
  DATE: "Date",
  TYPE: "Type",
  CURRENCY: "Monnaie",
  STATUS: "Statut",
  CREDIT: "Crédit",
  EDIT: "Modifier",
  FIELD_REQUIRED: "Ce champ est obligatoire",

  SUBMIT: "Soumettre",
  LOTTO_GAMES: "Jeu de loto",
  WELCOME_TO_888: "Bienvenue au 888 Loto",
  BET_SLIP: "Ticket de pari",
  LOTTO_RESULTS: "Résultats du loto",

  SUBMIT_ON_FORGOT_PASSWORD: "Soumettre",
  INSUFFICIENT_FUNDS: "Fonds insuffisants",
  INSUFFICIENT_FUNDS_MSG:
    "Vous devez effectuer un dépôt d'argent sur votre compte de jeu  pour effectuer cet achat.",
  PRICE_AMOUNT: "Prix Montant",
  CANCELED: "Annulé",
  PAY_AMOUNT: "Montant à payer",
  ACCOUNT_EMAIL_NOT_FOUND:
    "Erreur : Aucun compte n'est associé à cette adresse email",
  DEPOSIT: "Dépôt",
  DEPOSIT_NOW: "Faire un Dépôt",
  PENDING: "En attente",
  WITHDRAW_AMOUNT: "Montant du retrait",
  COPY_THE_PAY_WALLET_ADDRESS:
    "Vous pouvez également copier l'adresse du portefeuille de paiement ici",
  COPY: "Copier",
  DONE: "Terminé",
  WITHDRAW_AMOUNT_MSG: "Veuillez saisir le montant que vous souhaitez retirer",
  WALLET_ADDRESS_MSG: "Veuillez saisir l'adresse de votre portefeuille ici",
  WITHDRAW: "Retrait",
  MY_TICKETS: "Mes tickets",
  DRAW_DATE: "Date du tirage",
  BET_ID: "Bet Id",
  COST: "Coût",

  LOGIN: "Se connecter",
  LOGIN_ALT: "Enregistrement",
  DETAIL: "Détail",
  NEXT_PAGE: "Page suivante",
  PREVIOUS_PAGE: "Page précédente",
  PAGE: "Page",
  CURRENT_PAGE: "Page actuelle",
  LOGOUT: "Déconnexion",
  NO_ITEMS_IN_CART: "Il n'y a pas d'articles dans votre panier",
  YOUR: "Votre",
  WALLET: "Portefeuille",
  CART_EMPTY:
    "Aucune ligne de jeu dans votre panier. Veuillez utiliser le bouton 'Ajouter une ligne' ci-dessous pour ajouter une ligne de jeu.",
  PASSWORD_RESET_EMAIL:
    "Nous vous avons envoyé un email contenant le lien pour réinitialiser votre mot de passe.",
  PROFILE: "Profil",
  EDIT_PROFILE: "Modifier Profil",
  BET_PLACED_MSG: "Votre commande a été bien enregistrée !",
  INCREASE_YOUR_CHANCES:
    "Augmentez vos chances de gagner en achetant plus de tickets",
  ONE_TICKET_ONE_CHANCE: "1 ticket = 1 chance de gagner",
  ALL_DEPOSITS_SUBJECT_NETWORK_FEES:
    "All deposits are subject to the Tron network fees. This fee is the same for any amount. Send more to save on network fees (Recommended to send 10 USD to save on fees).",
  GENERATED_WALLET_ADDRESS_VALID_24:
    "The generated wallet address is only valid for 24hrs. After 24hrs generate a new deposit address to send funds.",
  PLAY_AGAIN: "Jouez encore !",
  ORDER: "Commande",
  ORDER_CONFIRMATION: "Confirmation de commande",
  HOW_TO_ADD_CREDIT: "Comment ajouter un crédit",
  WITHDRAWAL_REQUEST_SUCCESS:
    "Votre demande de retrait a bien été reçue. Elle sera traitée dans les plus brefs délais.",
  WITHDRAWAL_REQUEST_ERROR:
    "Une erreur s'est produite lors de la demande de retrait.",
  CREATE_ANOTHER_WITHDRAWAL:
    "Pour initier une autre demande de retrait, veuillez appuyer sur le bouton suivant.",
  WITHDRAWAL_OF_PRIZES: "Retrait des gains",
  NUMBERS_NOT_SELECTED_MSG:
    "Vous n'avez pas sélectionné vos numéros. Veuillez cliquer ici pour les sélectionner.",
  ENTER_A_NUMBER: "Saisir un nombre",
  VALUE_MUST_BE_LESS_THAN: "La valeur doit être inférieure ou égale à",
  GAME: "Jeu",
  PLACED_DATE: "Date de Placement",
  DATA_NOT_AVAILABLE: "Les données ne sont pas disponibles pour le moment.",
  COMBINATION: "Combinaison :",
  SINGLE_LOTTERY_BET_AMOUNT: "Montant du pari unique",
  TOTAL_WINNINGS: "Total des gains",
  TICKET_NUMBER: "Numéro du ticket",
  DRAW_NUMBER: "Numéro de tirage",
  SELLER: "Vendeur",
  PURCHASE_DATE: "Date d'achat",
  RESULT: "Résultat du tirage",
  MY_NUMBERS: "Mes numéros joués",
  LOADING: "Chargement en cours...",
  TOTAL_COST: "Coût total",
  TOTAL: "Total",

  PLACED: "Placé",
  LOST: "Perdu",
  WON: "Gagné",

  ERROR_EMAIL_PASSWORD: "Erreur : Email / Mot de passe incorrect",
  OK: "Ok",
  DEBIT: "Débit",
  LOTTO_TICKET: "ticket de loto",
  REFUND: "Remboursement",
  WINNING: "Gagner",
  SUCCESSFUL: "Réussi",
  FAILED: "Échoué",

  EMAIL_ALREADY_TAKEN: "Cet e-mail est déjà pris.",
  fields: {
    email: "email",
    password: "Code secret",
  },
  SELECT_SIX_NUMBERS: "Please Select Six Numbers Per Ticket",
  ACCOUNT_CREATED_MSG: "Votre compte a été créé.",
  ACCOUNT_CREATED_INST: "",
  ACCOUNT_CREATED_MSG_EMAIL:
    "Votre compte a été créé et nous vous avons envoyé un e-mail contenant des instructions pour vérifier votre compte.",
  ACCOUNT_CREATED_INST_EMAIL:
    "Veuillez suivre les instructions contenues dans l'e-mail pour vérifier votre compte de jeu.",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  TOKEN_EXPIRED: "Votre Token a expiré. Veuillez réessayer.",
  TOKEN_EXPIRED_2: "Réinitialiser le mot de passe",
  WITHDRAWABLE: "Retrait possible",
  NOT_ENOUGH_BALANCE: "Votre solde est insuffisant pour effectuer ce retrait.",

  SIGN_IN: "S'inscrire",
  SMS_REGISTRATION: "Inscription par SMS",
  EMAIL_REGISTRATION: "Inscription par email",
  MOBILE_NUMBER: "Numéro de téléphone mobile",

  OR: "OU",
  NOT_REGISTERED_YET: "Vous n'avez pas de compte?",
  CREATE_ACCOUNT_AND_START: "Créez un compte gratuit et commencez à gagner",
  START_WINNING: "et commencer à gagner.",
  CREATE_ACCOUNT_AND_START_EARNING:
    "Créez un compte maintenant et commencez à gagner.",
  REGISTER: "S'inscrire ",

  SEND_RESET_LINK: "Envoyer le lien de réinitialisation",
  REMOVE_THE_LEADING:
    "Supprimez le 0 du début de votre numéro de téléphone mobile",
  IM_NOT_ROBOT: "Je ne suis pas un robot",
  SEND_OTP: "Envoyer le code",

  VERIFY_OTP: "Vérifier le code OTP",
  ENTER_CODE: "Saisir le code",
  VERIFY: "Vérifier",
  THE_OTP_IS_ONE_TIME:
    "Le code OTP est un mot de passe à usage unique qui vous permet d'en définir un nouveau.",
  REMEMBER_ME: "Se souvenir de moi",

  RESET_PASSWORD: "Réinitialiser le mot de passe",
  SET_PASSWORD: "Définir le mot de passe",
  INTRODUCE_NEW_PASSWORD: "Saisir votre nouveau mot de passe",
  REPEAT_PASSWORD: "Répéter mot de passe",
  FOURPINCODE: "Entrer un code secret à 4 chiffres",
  REPEATPIN: "Répéter le code secret",
  NAME: "Nom",
  FAMILY_NAME: "Nom de famille",
  PLEASE_MAKE_A_PAYMENT_USING_QR:
    "Veuillez effectuer un paiement en utilisant le code QR ci-dessous",

  FORGOT_PASSWORD: "Mot de passe oublié ?",
  EMAIL_ADDRESS: "Adresse email",
  AN_OTP_ONE_TIME_PASSWORD:
    "Le code OTP est un mot de passe à usage unique qui vous permet d'en définir un nouveau.",
  YOU_HAVE_LOGGED_OUT: "Vous êtes déconnecté.",
  AGAIN: "encore.",
  ACCOUNT: "Compte",
  NOTIFICATIONS: "Notifications",
  CURRENCY: "Sélectionnez la devise",
  SELECT_CURRENCY: "Sélectionnez la devise",

  VERIFY_EMAIL: "Vérifier l'adresse email",
  PLEASE_WAIT_ACCOUNT_VERIFYING:
    "Veuillez patienter. Nous vérifions votre compte",
  YOUR_ACCOUNT_HAS_BEEN_VERIFIED:
    "Votre compte a été vérifié. Vous serez automatiquement connecté dans 5 secondes.",

  SORRY_YOUR_ACCOUNT_HAS_FAILED:
    "Désolé, la vérification de votre compte a échoué",
  IF_YOUR_ARE_NOT_AUTO_SIGNED_IN:
    "si vous n'êtes pas connecté automatiquement.",
  ENTER_MOBILE_NUMBER_WITHOUT_ZERO:
    "Note : Saisissez votre numéro de téléphone mobile sans le chiffre zéro au début.",

  USERNAME: "Nom d'utilisateur",
  PASSWORDS_DONT_MATCH: "Les mots de passe ne correspondent pas",
  ERROR_MOBILE_PASSWORD: "Numéro ou Mot de passe incorrect",
  ENTER_PHONE_NUMBER: "Votre numéro de téléphone",
  USER_NOT_FOUND: "Compte non trouvé",
  WALLET_ADDRESS_COPIED: "Adresse du portefeuille copié",
  COPIED: "copié",
  MOBILE_ALREADY_TAKEN: "Ce numéro de téléphone est déjà enregistré",
  REPEAT_EMAIL: "Répéter l'e-mail",
  REPEAT_MOBILE_NUMBER: "Répéter le numéro de téléphone mobile",
  EMAILS_DONT_MATCH: "Les emails ne correspondent pas",
  MOBILES_DONT_MATCH: "Les numéros ne correspondent pas",
  ENTER_EMAIL: "Veuillez saisir l'adresse email",
  PASSWORD_CHANGED: "Mot de passe modifié",

  SEARCH_CRYPTO_EXCHANGE_OFFICES: "Search crypto exchange offices",
  SEARCH_PERFECT_MONEY_DEALERS: "Search perfect money dealers",
  SUCCESSFUL_EV_NUMBER: "Successful",
  WATCH_THIS_VIDEO_HOW_TO_USE_PERFECT_MONEY:
    "Watch this video to learn how to use perfect money to deposit",

  PAY_WITH_PERFECT_MONEY: "Pay With Perfect Money",
  PAY_WITH_CRYPTO: "Payer avec des crypto-monnaies",
  INVALID_EV_NUMBER: "Invalid ev-number",
  TICKET_ID: "Identifiant du ticket",
  WITHDRAW_AMOUNT_LIMIT: "Le montant minimum de retrait est de 50 USD",
  ONLY_AMOUNT_ABOVE_4USD: " Only for amounts above USD 4",

  /*jackpot-aruba-translation-start*/

  ALL_RESULTS: "Ba Résultats Nionso",
  PLAY_NOW: "JOUEZ MAINTENANT",
  DRAW_EVERY_5_MIN: "TIRAGE TOUTES LES 5 MINUTES",
  COMING_SOON: "BIENTÔT",
  LATEST_LOTTO_RESULTS: "Derniers résultats de la loterie",
  SELECT_DRAW_DATE: "Sélectionnez la Date du Tirage",
  WINNING_NUMBERS: "Numéros Gagnants",
  validations: fr,

  /*jackpot-aruba-translation-start*/

  DRAW_EVERY_5_MINS: "Tirage toutes les 5 mins",
  PLEASE_ENTER_YOUR_MOBILE:
    "Veuillez entrer votre numéro de téléphone pour recevoir un code de vérification par SMS",

  BACK_TO_LOGIN: "Back to Login",

  LATEST_LOTTERY_RESULTS: "Derniers résultats de la loterie",

  PERSONAL_DATA: "Données personnelles",

  NUMBER_DRAWS: "Nombre de tirages",
  QUICK_PICK: "Sélection automatique",
  CLEAR: "Réinitialiser",
  LINE: "Ticket",
  DRAW_CLOSES_IN: "Prochain tirage dans",
  UPLOAD_NOW: "Upload now",
  SELECT_OPERATOR: "SELECTIONNER OPERATEUR",
  UPLOAD: "Ajouter",
  UPLOAD_AVATAR: "Ajoutervotre avatar",
  PAYMENT_AUTH_REQ_MSG:
    "Vous allez recevoir une demande de paiement sur votre numéro mobile:",

  KYC_DOCS: "DOCS KYC",
  ACCOUNT_ALREADY_EXISTS: "COMPTE EXISTE DEJA",
  CONTACT_SUPPORT_PERSONAL_DATA:
    "Vous souhaitez modifier vos informations personnelles ? Cliquez ici pour contacter le service d’assistance. ",
  SUPPORT: "d’assistance",
  DRAW_EVENT_ON: "Tirage ",
  DRAWS: "Nbre Tirage",

  NUMBER_OF_DRAWS: "Nombre de tirages",

  LINES: "Tickets",

  PHONE_NUMBER: "Numéro de téléphone",
  PASSWORD: "Code secret",

  ERROR_INVALID_PARAMETER: "ERREUR-Paramètre invalide",
  HOW_MAY_WE_HELP: "En quoi pouvons-nous vous aider?",
  WHEN_YOU_SEND_200FC: "Lorsque vous dépensez 200FC",

  CHECK_THE_LATEST_RESULTS:
    "Tala ba résultats ya sika ya tirage au sort po oyeba soki o gagné! Ba résultats ebimaka mbala moko sima ya tirage au sort moko na moko.",
  I_AGREE_TO_THE_END:
    "J'ai lu et j'accepte les termes et conditions d'utilisation du site web. Je confirme avoir plus de 18 ans et accepte de recevoir d'autres communications de ce site par e-mail et sms.",
  REMOVE_DEPOSIT_TEXT:
    "Désolé, votrecompte a étérestreint pour des vérificationslégales. Veuillezfournir les documents ci-après pour débloquer le compterapidement. Vous pouvezcontacternotre service client sivousavezbesoind’aide.",
  IF_EXISTS_SMS_SENT:
    "Vous allez recevoir un SMS dans quelques instants. Assurez vous que le numéro de téléphone est correct",

  NO_DRAWS_FOUND: "Aucun tirage pour l'instant",
  ENTER_NUMBER_WITHOUT_ZERO:
    "Veuillez entrer votre numéro de téléphone sans le zéro",

  EDIT_TEXT: "Modifier",
  VIEW_TEXT: "Afficher",
  SUBMITTED: "Envoyer",

  CLICK_TO_UPLOAD: "Cliquer pour charger",

  PROOF_OF_ADDRESS: "Justificatif de residence",

  UPDATE_YOUR_IMAGE: "Ajouter photo de profil",

  UPLOAD_PROOF_ADDRESS: "Ajouter un justificatif de résidence",
  UPLOAD_ID_PASSPORT: "AjouterCarte IdentitéouPasseport",
  UPLOAD_WEBCAM_PHOTO: "AjouterPhoto caméra",
  UPLOAD_SOURCE_OF_FUNDS: "AjouterOrigine des fonds",
  UPLOAD_CC_FRONT: " AjouterCarte de crédit Recto",
  UPLOAD_CC_BACK: "AjouterCarte de crédit Verso",
  FILE_UPLOADED: "Fichier envoyé",
  MIN_DEPOSIT_500: "Dépôt minimum 200 FC / maximum 1.000.000 FC",

  DRC_DEPOSIT_LIMIT_CDF: "Dépôt entre 200 FC minimum na 1.000.000 FC maximum",
  DRC_WITHDRAW_LIMIT_CDF:
    "Retraits autorisés entre 1.000 FC minimum and 1.000.000 FC maximum ",
  NO_OPERATOR_AVAILABLE:
    "Les paiements, dépôts et retraits sont momentanément indisponibles",
  NO_OPERATOR_FOUND: "Réseau mobile non identifié",
  DEPOSIT_AMOUNT: "Montant à déposer",

  /*  ***888play-aviator-only-down***  */

  FIRST_NAME: "Nom de profil",
  LAST_NAME: "Nom complet",
  USER_NAME: " ",

  ENTERADDRESS: " ",
  POSTCODE: "Code Postal",
  CITY: " ",
  DOB: "Date de naissance",

  ADDRESS: "Adresse",
  STREETADDRESS: "Adresse",
  CITYORTOWN: "Ville",
  PROVINCE: "Province",
  GENDER_TEXT: "Sexe",

  MY_BETS: "Mes paris",
  MALE_TEXT: "Homme",
  FEMALE_TEXT: "Femme",
  OTHERS_TEXT: "Autre",
  SELECT_DATE: "Choisir la date",

  ENTER_DEPOSIT_AMT: "dépôt - entrez le montant:",
  ENTER_WITHDRAWAL_AMT: "Retrait - entrez le montant",
  DEPOSIT_ACCEPTED:
    "Your deposit request has been accepted. Please authorize the request recieved on your phone",

  PLAY_AVIATOR_FUNDS: "Vous avez maintenant de quoi jouer à Aviator.",
  PLAY_NOW: "jouer maintenant",
  YOUR_DEPOSIT_FAILED: "votre dépôt a échoué",
  YOUR_DEPOSIT_SUCCESSFUL: "Dépôt réussi - félicitations!",
  TRY_NOW: "Re-éssayer",

  SOMETHING_WENT_WRONG: "Quelque chose n'a pas fonctionné correctement",
  PLEASE_CHECK_AGAIN: "Please check if you have enough credit and try again.",
  NO_MONEY_WAS_TAKEN:
    "NB: Votre argent est toujours disponible. Rien n'a été prélevé de votre compte",
  IF_NOT_CORRECT_AND_WANT_CHANGE: "Si vous souhaitez modifier ou corriger,",
  TO_CONNECT_WITH_OUR_HELP: "pour contacter un agent 888 pour vous assister",

  PLEASE: "Veuillez patienter...",
  CLICK_NOW: "click now",
  YOU_CAN_NOW_PLAY_AVIATOR:
    "You can now play Aviator with your deposited funds",
  TRANSACTION_DATE_TIME: "Date et Heure de la transaction",

  REFERENCE: "Ref:",
  REPORT_AN_ISSUE_TO_SUPPORT: "Signaler un problème au service client",
  WITHDRAWAL_REQUEST_SUCCESS: "votre retrait a réussi",
  YOUR_BALANCE_WILL_BE_UPDATED:
    "YouLe montant de votre solde sera mis à jour. Les fonds que vous avez retirés apparaîtront sur votre compte mobile.",
  WE_ARE_PROCESSING:
    "Votre retrait est en cours de traitement, ne quittez pas cette page.",

  DONE: "OK",
  YOUR_WITHDRAWAL_FAILED: "votre retrait a échoué",
  PLEASE_CHECK_ENOUGH_BALANCE:
    "Vous pouvez re-essayer ou signaler le problème directement à notre service client.",
  REPLAY_AND_SHARE: "rejouer & partager",

  FAILED_DEPOSIT_TRANSACTION:
    "Failed deposit transaction will be removed from your wallet.",

  MONEY_HAS_BEEN_CREDITED: "L'argent a été déposé sur votre solde Aviator 888",
  MONEY_HAS_BEEN_DEBITED: "L'argent a été retiré de votre solde Aviator 888",
  YOU_DEPOSITED: "Vous avez déposé",
  YOU_WERE_DEPOSITED: "Vous avez été débité de",

  AVIATOR_ROUND: "Vol échoué",
  STATUS: "Statut",
  COMPLETED: "Terminé",
  PENDING: "En Attente",
  REQUEST_ON: "Demandé le",
  REQUESTED_ON: "RequeDemandé le",
  DEPOSIT: "confirmer",
  WITHDRAW: "confirmer",
  YOU_RECIEVED: "Vous avez reçu",

  DISPLAY_ALL_TRANSACTIONS: "Afficher toutes les transactions",
  AVAILABLE_TO_WITHDRAW: "disponible pour le retrait",
  PLEASE_WAIT_AN_HOUR:
    "Veuillez patienter pendant que nous traitons cette transactions. Vous pouvez également signaler le problème direcement à notre service client.	",
  FAILED_DEPOSIT_TXN: "La transaction échouée sera annulée.",

  WAITING_FOR_APPROVAL:
    "votre dépôt est en cours de traitement. ne quittez pas cette page.",
  DEPOSIT_BEING_PROCESSED: "VOTRE DÉPÔT EST EN COURS DE TRAITEMENT",
  RECIEVE_PAYMENT_REQUEST:
    "Vous allez recevoir une demande de paiement sur votre téléphone.",
  PLS_APPROVE_PAYMENT_REQUEST:
    "Veuillez approuver avec votre code PIN mobile money.",
  MAY_CLOSE_TRAY:
    "Vous pouvez quitter cette page. Le statut de ce dépôt est disponible dans l’historique de vos transactions",
  CONTINUE: "OK - CONTINUER",
  AVAILABLE_BALANCE: "Solde disponible:",
  WITHDRAWAL_AMT: "Montant à retirer:",
  DISTRIBUTED_AMT: "Montant envoyé:",
  CHANGE_PIN: "Valider",
  PIN_CHANGED: "Votre code PIN a été mis à jour",
  CANCEL: "annuler",
  UPDATE: "valider",
  NO_RECORDS: "Aucune transaction",
  HOME_VIEW_LAUNCH_AVIATOR: "HOME VIEW Launch Aviator",

  ID_AND_PASSPORT: "Carte d'Identité",
  WEBCAM_TEXT: "Photo selfie",
  SOURCE_OF_FUNDS: "Origine des fonds",
  CREDIT_CARD: "Carte de crédit",

  REPEAT_NEW_PIN: "Repeat new pin",
  NEW_PIN: "New pin",
  WINNINGS: "Gains",
  CRASH_TEXT: "Crash",
  NEW_FUNDS_HAVE_ARRIVED: "DÉPÔT RÉUSSI",
  SUCCESSFULLY_DEPOSITED: "Vous avez reçu",
  WIN_TEXT: "Vol réussi: GAGNÉ",
  CLICK_HERE: "Cliquez ici",
  WAGER: "Mise",
  PLEASE_APPROVE_DEPOSIT_REQUEST:
    "Veuillez valider la demande de confirmation que vous avez reçu sur votre téléphone.",

  MESSAGES: "messages",
  All_TXN: "toutes les transactions",
  WINS: "gains",
  NEW_PIN: "Entrez votre nouveau code à 4 chiffres",
  REPEAT_NEW_PIN: "Confirmez votre nouveau code",

  YOUR_WITHDRAWAL_REQUEST_ACCEPTED: "Retrait réussi",
  REPEAT_PIN_DOES_NOT_MATCH: "Les deux codes PIN ne correspondent pas",
};

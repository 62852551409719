import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";

function getCartTotal(state) {
  let intTotal = 0;
  let arrBets = state.arrBets;
  for (let i = 0; i < arrBets.length; i++) {
    let objBet = arrBets[i];

    // let blnIsComplete = this.isBetComplete(objBet);
    // if (!blnIsComplete) {
    //   continue;
    // }
    let intNumDraws = parseInt(objBet.intNumDraws);
    let intStakeAmount = parseInt(objBet.intStakeAmount);

    //let objLottoConfig = this.getLottoConfig(objBet.strLottoId);
    //let { intLineCost } = objLottoConfig;
    let intLines = objBet.arrLines.length;
    intTotal += intLines * intStakeAmount * intNumDraws;
  }

  return intTotal;
}

function emitCartUpdate(state) {
  //console.log("emitting");

  //console.log("state is ", state);
  //console.log("carttotal is");

  let intCartTotal = getCartTotal(state);

  Vue.prototype.$utils.emitWCEvent2(Vue.prototype.$root, "cartUpdate", {
    intNumItems: state.arrBets.length,
    intCartTotal,
  });
}

function emitUserInfo(state) {
  let strBalance = "";
  let strPendingWithdrwal = "";
  let strMobile = "";
  let strAvatarUrl = "";

  if (state.objUserProfile) {
    strMobile = state.objUserProfile.mobile;
    strAvatarUrl = Vue.prototype.$utils.getAvatarFileName(state.objUserProfile);
  }

  if (state.objWalletStats) {
    strBalance = state.objWalletStats.fltEffectiveBalance;
  }

  let objUserInfo = {
    strBalance,
    strMobile,
    strPendingWithdrwal,
    strAvatarUrl,
  };

  console.log("emtting", objUserInfo);

  // this.$utils.emitWCEvent2(this, "userInfo", objUserInfo);
  Vue.prototype.$utils.emitWCEvent2(
    Vue.prototype.$root,
    "userInfo",
    objUserInfo
  );
}

function emitState(state) {
  emitUserInfo(state);
}

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    blnLoading: false,
    objBrandingSettings: {},

    objSignInData: { objUser: {}, strToken: "" },
    objWalletStats: {},
    objUserProfile: {},
    objCredentials: {},
  },
  getters: {
    objUserProfile: (state) => {
      try {
        return state.objSignInData.objUser;
      } catch (e) {
        console.log(e);
      }
      return {};
    },

    strToken: (state) => {
      try {
        return state.objSignInData.strToken;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    objUser: (state) => {
      try {
        return state.objSignInData.objUser;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    blnIsUserLoggedIn: (state) => {
      try {
        if (state.objSignInData.objUser.player_id) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },

    objWalletStats: (state) => {
      try {
        return state.objWalletStats;
      } catch (e) {
        console.log(e);
      }
      return "";
    },

    objCredentials: (state) => {
      try {
        return state.objCredentials;
      } catch (e) {
        console.log(e);
      }
      return {};
    },

    objBrandingSettings: (state) => {
      return state.objBrandingSettings;
    },
  },

  mutations: {
    emitState(state) {
      //console.log("mutation");
      emitCartUpdate(state);
      emitUserInfo(state);
    },
    resetState(state) {
      state.objSignInData = { objUser: {}, strToken: "" };
      emitCartUpdate(state);
    },
    signIn(state, objSignInData) {
      try {
        console.log(objSignInData);

        let { objUser, strToken } = objSignInData;

        state.objSignInData.objUser = objUser;
        state.objSignInData.strToken = strToken;
        emitState(state);
      } catch (e) {
        console.log(e);
      }
    },

    logout(state) {
      try {
        // alert(state.objSignInData.strToken)

        state.objSignInData = { objUser: {} };
        state.objSignInData.strToken = "";
        // alert(state.objSignInData.strToken)
        state.arrBets = [];
        emitCartUpdate(state);
        emitState(state);
      } catch (e) {
        console.log(e);
      }
    },
    setWalletStats(state, objWalletStats) {
      try {
        state.objWalletStats = objWalletStats;
      } catch (e) {
        console.log(e);
      }
      emitState(state);
    },
    setUserProfile(state, objUserProfile) {
      try {
        state.objSignInData.objUser = objUserProfile;
      } catch (e) {
        console.log(e);
      }
      emitState(state);
    },

    setCredentials(state, objCredentials) {
      try {
        state.objCredentials = objCredentials;
      } catch (e) {
        console.log(e);
      }
    },

    setAvatar(state, strFileName) {
      try {
        state.objSignInData.objUser.avatar_filename = strFileName;
      } catch (e) {
        console.log(e);
      }
      emitState(state);
    },

    setBrandingSettings(state, objBrandingSettings) {
      try {
        state.objBrandingSettings = objBrandingSettings;
      } catch (e) {
        console.log(e);
      }
    },
  },
  actions: {},
  modules: {},
});

import MainLayout from "../layouts/MainLayout.vue";
export default function getRoutes({ Vue, store }) {
  const authenticateUser = (to, from, next) => {
    return next();
    try {
      const blnIsUserLoggedIn = store.getters["blnIsUserLoggedIn"];
      console.log(blnIsUserLoggedIn);
      if (!blnIsUserLoggedIn) {
        console.log("redirecting");
        return next({
          name: "SignIn",
        });
      }
    } catch (e) {
      console.log(e);
      return next({
        name: "SignIn",
      });
    }
    next();
  };

  const routes = [
    {
      path: "/",
      name: "",
      component: MainLayout,
      children: [
        {
          path: "/u",
          component: () =>
            import(/* webpackChunkName: "home" */ "../layouts/UserLayout.vue"),
          //  beforeEnter: authenticateUser,
          children: [
            {
              path: "profile",
              name: "Profile",
              component: () =>
                import(
                  /* webpackChunkName: "profile" */ "../views/Profile.vue"
                ),
            },
            {
              path: "edit-profile",
              name: "EditProfileForm",
              component: () =>
                import(
                  /* webpackChunkName: "profile" */ "../views/EditProfileForm.vue"
                ),
            },

            {
              path: "transactions",
              name: "Transactions",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "transaction" */ "../views/Transactions.vue"
                ),
            },

            {
              path: "p-transactions",
              name: "PlayerTransactions",
              props: true,
              component: () => import("../views/PlayerTransactions.vue"),
            },

            {
              path: "transaction-detail",
              name: "TransactionDetail",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "transaction" */ "../views/TransactionDetail.vue"
                ),
            },

            {
              path: "deposit",
              name: "Deposit",
              props: true,
              component: () =>
                import(/* webpackChunkName: "picker" */ "../views/Deposit.vue"),
            },

            {
              path: "withdrawal",
              name: "Withdrawal",
              component: () =>
                import(
                  /* webpackChunkName: "wallet" */ "../views/Withdrawal.vue"
                ),
            },
            {
              path: "my-bets",
              name: "MyBets",
              component: () =>
                import(/* webpackChunkName: "mybets" */ "../views/MyBets.vue"),
            },

            {
              path: "change-pin",
              name: "ChangePINForm",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "transaction" */ "../views/ChangePINForm.vue"
                ),
            },
          ],
        },
        {
          path: "",
          name: "Home",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        },
      ],
    },
  ];

  return routes;
}

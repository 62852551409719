<style src="../node_modules/buefy/dist/buefy.min.css"></style>

<style src="./css/customstyle.min.css"></style>
<style src="./css/landingcss.min.css"></style>

<template>
  <div id="app">
    <div id="myContainer" ref="myContainerRef"></div>
    <!-- <b-button label="Click me" @click="handleClick"></b-button> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import store from "./store";
import { getRouter } from "./router";
let router = getRouter({ store });
import axiosPlugin from "./plugins/axios";
import utilsPlugin from "./plugins/utils";
import io from "socket.io-client";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import {
  faAngleLeft,
  faAngleRight,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleLeft, faAngleRight, faCopy);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

import Buefy, { ConfigProgrammatic } from "../buefy";
import {
  Table,
  Input,
  Button,
  Field,
  Dialog,
  Toast,
  Tabs,
  Datepicker,
  Pagination,
  Snackbar,
} from "../buefy";

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
});

ConfigProgrammatic.setOptions({
  defaultIconPack: "fas",
  defaultIconComponent: "font-awesome-icon",
});

import { i18n } from "./i18n";

Vue.use(axiosPlugin, { router, store, i18n });
Vue.use(utilsPlugin, { router, store, i18n });

Vue.use(Table);
Vue.use(Input);
Vue.use(Button);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Snackbar);
Vue.use(Toast);

Vue.use(Tabs);
Vue.use(Datepicker);
Vue.use(Pagination);

Vue.prototype.strLottoId = "";

import { mapGetters } from "vuex";

import "./vee-validate";
export default {
  name: "app",
  emits: ["stats", "appLoaded", "mFromApp"],

  props: {
    cid: {
      type: String,
      default: "888drc",
    },

    bid: {
      type: String,
      default: "888drc",
    },
    mode: {
      type: String,
      default: "",
    },
    lang: {
      type: String,
      default: "en",
    },
  },
  i18n,
  router,
  store,
  computed: {
    ...mapGetters([
      "blnIsUserLoggedIn",
      "strToken",
      "objWalletStats",
      "objUser",
      "objUserProfile",
    ]),
  },
  created() {
    this.$i18n.locale = this.lang;

    window.addEventListener("mFromSite", (event) => {
      let objEventData = event.detail;

      let strMsg = objEventData.strMsg;
      if (strMsg == "openAviator") {
        this.openAviator();
      } else if (strMsg == "emitProfile") {
        let objUserProfile = this.objUserProfile;

        const event = new CustomEvent("mFromApp", {
          detail: {
            strType: "profile",
            objUserProfile,
          },
        });
        window.dispatchEvent(event);
      }
    });

    window.addEventListener("trayOpen", (event) => {
      let strPage = event.detail;
      let strAppPage;
      if (strPage == "deposit") {
        strAppPage = "Deposit";
      } else if (strPage == "withdraw") {
        strAppPage = "Withdrawal";
      } else if (strPage == "transaction") {
        strAppPage = "Transactions";
      } else if (strPage == "profile") {
        strAppPage = "Profile";
      } else if (strPage == "editProfile") {
        strAppPage = "EditProfileForm";
      } else if (strPage == "changePIN") {
        strAppPage = "ChangePINForm";
      } else {
        console.log("UNKNOWN PAGE");
      }
      if (strAppPage) {
        this.$router.replace({ name: strAppPage });
      }
    });

    window.addEventListener("login", (event) => {
      let obj = event.detail;
      this.$store.commit("signIn", obj);
    });

    window.addEventListener("logout", (event) => {
      this.$store.commit("logout");

      //this.setAxiosToken("");
    });
  },
  beforeDestroy() {},
  async mounted() {
    Vue.prototype.$strClientId = this.cid;
    Vue.prototype.$strBrandingId = this.bid;

    if (this.blnIsUserLoggedIn) {
      this.setAxiosToken(this.strToken);
      this.startWS(this.strToken);

      this.emitProfile();

      // this.ping();
    }

    let event = new CustomEvent("appLoaded", {
      detail: {
        blnIsUserLoggedIn: this.blnIsUserLoggedIn,
        objUserProfile: this.objUserProfile,
      },
    });

    window.dispatchEvent(event);

    Vue.prototype.$root = this.$root;
    //this.$store.commit("emitState");

    // window.setTimeout(() => {
    //   this.$store.commit("emitState");
    // }, 1000);

    //this.initStoreLottos();
    await this.load();

    // this.$router.replace({ name: "Deposit" });
    //this.loadRecaptcha();

    //document.getElementById("someOtherDiv").appendChild(photo);

    // const socket = io("http://localhost:3000");
    // socket.emit("chat message", "wow");

    // socket.on("chat message", function (msg) {
    //   alert("Received message");
    // });
  },
  methods: {
    closeTray() {
      const event = new CustomEvent("mFromApp", {
        detail: {
          strType: "closeTray",
        },
      });
      window.dispatchEvent(event);
    },
    handleClick() {
      this.$buefy.toast.open({
        message:
          "💰💰💰<br><b>" +
          this.$t("NEW_FUNDS_HAVE_ARRIVED") +
          "</b><br>Vous avez reçu 600 FC",
      });
    },
    emitProfile() {
      let objUserProfile = this.objUserProfile;

      const event = new CustomEvent("mFromApp", {
        detail: {
          strType: "profile",
          objUserProfile,
        },
      });
      window.dispatchEvent(event);
    },
    startWS(strToken) {
      let strUrl =
        "https://websocket.prod.imprexis-platform.imprexisplatform.com";
      const socket = io(strUrl, {
        reconnectionDelayMax: 10000,
        auth: {
          token: strToken,
        },
      });

      socket.on("bal", (objData) => {
        //console.log(objData);
        //alert("REceived data");
        let objWalletStats = { fltTotalSettled: objData.fltBalance };
        this.sendWalletMessage(objWalletStats);
      });

      socket.on("e", (objData) => {
        //console.log(objData);
        this.handleEvent(objData);
      });
    },
    async openAviator() {
      let strToken = await this.getSpribeUserToken();
      let strPlayerId = this.objUser.player_id;
      let strSpribeLink;
      let strCurrency = "ZMW";
      let strLang = "fr";
      let strOperator = "";

      if (process.env.NODE_ENV == "production") {
        // strOperator = "aviatorzm";
        strOperator = "lloto";
        strCurrency = "CDF";

        strSpribeLink = `https://launch.spribegaming.com/aviator?user=${strPlayerId}&token=${strToken}&lang=${strLang}&currency=${strCurrency}&operator=${strOperator}`;
      } else {
        strOperator = "aviatorzmstg";
        strSpribeLink = `https://dev-test.spribe.io/games/launch/aviator?user=${strPlayerId}&token=${strToken}&lang=${strLang}&currency=${strCurrency}&operator=${strOperator}`;
        strOperator = "llotostg";
        strCurrency = "CDF";
        // strSpribeLink = `https://launch.spribegaming.com/aviator?user=${strPlayerId}&token=${strToken}&lang=fr&currency=CDF&operator=lloto`;
        strSpribeLink = `https://dev-test.spribe.io/games/launch/aviator?user=${strPlayerId}&token=${strToken}&lang=${strLang}&currency=${strCurrency}&operator=${strOperator}`;
      }

      //alert(strSpribeLink)

      let strOrigin = location.origin + "/";
      //strOrigin = "https://888play.cd";
      let strUrl =
        strOrigin + "aviator.php?l=" + encodeURIComponent(strSpribeLink);

      this.strSpribeLink = strUrl;
      location.href = strUrl;
    },
    async getSpribeUserToken() {
      let strUrl = "/u/spribe-token";

      let objData = await this.$myAxios.get(strUrl);

      console.log("aviator user token", objData);

      if (!objData.blnOK) {
        this.blnError = true;
        return;
      }

      this.blnLoaded = true;

      let strToken = objData.obj;
      return strToken;
    },
    sendWalletMessage(objWalletStats) {
      const event = new CustomEvent("mFromApp", {
        detail: {
          strType: "walletStats",
          objWalletStats,
        },
      });
      window.dispatchEvent(event);
    },
    handleDeposit(objEvent) {
      let fltAmount = objEvent.amt;
      this.$buefy.toast.open({
        duration: 10000,
        message:
          "💰💰💰<br><b>" +
          this.$t("NEW_FUNDS_HAVE_ARRIVED") +
          "</b><br>" +
          this.$t("SUCCESSFULLY_DEPOSITED") +
          " " +
          fltAmount +
          "FC",
      });
      window.setTimeout(() => {
        this.closeTray();
      }, 6000);
    },
    handlePayout(objEvent) {
      let fltAmount = objEvent.amt;

      this.$buefy.toast.open({
        duration: 10 * 1000,

        message:
          "💰💰💰<br><b>Payout Success</b><br>" +
          fltAmount +
          "FC was successfully paid out",
      });
      window.setTimeout(() => {
        this.closeTray();
      }, 6000);
    },

    handleEvent(objEventData) {
      let { objEvent } = objEventData;
      let strEventType = objEvent.t;
      console.log("event type is ", strEventType);

      switch (strEventType) {
        case "payout":
          this.handlePayout(objEvent);
          break;
        case "dep":
          this.handleDeposit(objEvent);
          break;
      }
    },
    async loadUserInfo() {
      if (!this.blnIsUserLoggedIn) {
        return;
      }
      let p1 = this.$myAxios.get("/u/wallet-stats");
      let p2 = this.$myAxios.get("/u/me");
      let arr = await Promise.all([p1, p2]);

      //console.log(arr);

      let objResponse1 = arr[0];
      let objResponse2 = arr[1];

      if (objResponse1.blnOK) {
        let objWalletStats = objResponse1.obj;
        //console.log("Setting wallet stats");

        this.$store.commit("setWalletStats", objWalletStats);
        this.sendWalletMessage(objWalletStats);
      } else {
        //console.log(objResponse1.strMessage);
      }

      if (objResponse2.blnOK) {
        let objUser = objResponse2.obj;
        this.$store.commit("setUserProfile", objUser);
      } else {
      }
    },

    async load() {
      this.loadUserInfo();
      return;
    },

    loadRecaptcha() {
      if (this.$strBrandingId != "888drc" && this.$strBrandingId != "hs") {
        return;
      }
      let strSiteKey = this.$utils.RECAPTCHA_SITE_KEYS[this.$strBrandingId];
      let script = document.createElement("script");
      script.id = "theid";
      script.src = `https://www.google.com/recaptcha/api.js?render=${strSiteKey}`;
      script.async = true;
      script.defer = true;

      let objApp = this.$refs["myContainerRef"];

      objApp.appendChild(script);
    },

    async ping() {
      try {
        let objResponse = await this.$myAxios.get("/u/ping");
        if (!objResponse.blnOK) {
          this.$store.commit("logout");
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
